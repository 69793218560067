const initialState  = {
    userProfile:[],
    AuthDetails: [],
    viewPolicy: [],
    planDetails: [],
    couponDetails: [],
    selectedPolicy : null,
    courseCount :[],
    recentUsers :[],
    userCount:[],
}

export default initialState;